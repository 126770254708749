* {
    margin: 0;
    padding: 0;
}

header {
    background: rgb(193, 35, 35);
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    margin: 0 auto;
}

.ielts-content {
    margin: 6rem 20px;
}

.table tr td {
    border: 1px black solid;
}

.float-nav {
    display: none;
    justify-content: space-between;
    position: fixed;
    bottom: 70px;
    right: 20px;
    /* background: #000; */
    width: 90px;
}

.float-nav i {
    font-size: 2.6em;
    color: #003477;
}

.float-nav i.disabled {
    font-size: 2.6em;
    color: #003477;
    opacity: 0.4;
}

select:invalid {
    color: #777b7e;
}

/* FOOTER */
footer {
    background: rgb(255, 255, 255);
    padding: 10px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    /* margin: 0 auto; */
}

.question-palette {
    display: flex;
    border: 1px solid #003477;
    padding: 10px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-right: 10px;
    cursor: pointer;
    width: 100%;
}

.question-palette.selected {
    background: #003477;
}

.question-palette__number {
    display: flex;
    justify-content: space-around;
}

.question-palette__number.active {
    display: none;
}

.question-palette__number span {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #003477;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 5px;
    font-size: 0.7em;
}

.question-palette__number span.active {
    background: #003477;
    color:white;
}

.question-palette__number span.bold {
    background: #003477;
    color:white;
}

.question-palette__number span:hover {
    background: #003477;
    color:white;
    cursor: pointer;
}

.question-palette__number span.selected {
    background: rgba(114, 29, 29, 0.595);
    border: none;
}

.question-palette__number span.active {
    background: rgba(37, 9, 9, 0.595);
}


/* -------- */

.center-container {
    background-image: linear-gradient(to right, white, #f6e7d5);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container-thankyou {
    background-image: url('../assets/images/bg-thankyou.png');
    background-repeat: no-repeat;
    background-size:cover;
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.box-token {
    padding: 40px;
    background: #ffffff;
    border-radius: 20px;
    text-align: center;
}

.form-control.token-input {
    text-align: center;
    margin: auto;
    width: 350px;
    height: 40px;
}

.answer-input {
    width: 200px;
}

.nav-part {
    /* width: 100%; */
    border: 1px solid #000;
    border-radius: 20px;
    padding: 20px;
}

.pane-left {
    border-right: 3px solid red;
    overflow: auto;
    height: 100%;
}

.pane-left-mobile {
    border-bottom: 3px solid red;
    overflow: auto;
    height: 100%;
}

.inner-pane {
    padding: 100px 30px;
}

.inner-pane-mobile {
    padding: 20px 30px 100px;
}

.inner-pane-top {
    padding: 100px 30px;
}


.box-test {
    border: 1px grey solid;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    margin: 10px;
    padding: 0;

}

.card-payment {
    background: #16181a;
    border-radius: 14px;
    max-width: 350px;
    position: relative;
    display: block;
    margin: auto;
    padding: 60px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 2px 10px 40px black;
    z-index: 999;
}

.logo-card {
    max-width: 50px;
    margin-bottom: 15px;
    margin-top: -9px;
}

.label-payment {
    display: flex;
    font-size: 10px;
    color: white;
    opacity: .4;
}

.input-payment {
    font-family: 'Work Sans', sans-serif;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    color: #dbdce0;
    transition: border-bottom .4s;
}

.input-payment:focus {
    border-bottom: 1px solid #1abc9c;
    outline: none;
}

.cardnumber {
    display: block;
    font-size: 20px;
    margin-bottom: 8px;
}

.name {
    display: block;
    font-size: 13px;
    width: 200px;
    float: left;
    margin-bottom: 15px;
}

.toleft {
    float: left;
}

.ccv {
    width: 50px;
    margin-top: -5px;
    font-size: 15px;
}

.receipt {
    background: #dbdce0;
    border-radius: 10px;
    padding: 3%;
    padding-top: 200px;
    max-width: 600px;
    display: block;
    margin: auto;
    margin-top: -180px;
    z-index: -1;
}

.col {
    width: 50%;
    float: left;
}

.bought-item {
    background: #f5f5f5;
    padding: 2px;
}

.bought-items {
    margin-top: -3px;
}

.cost {
    color: #be9337;
    font-size: 24px;
    font-weight: bold;
}

.description {
    font-size: 13px;
}

.price {
    font-size: 12px;
}

.comprobe {
    color: #dbdce0;
}

.proceed {
    position: absolute;
    transform: translate(170px, 30px);
    width: 170px;
    height: 40px;
    text-align: center;
    border-radius: 10px;
    font-size: 16px;
    border: none;
    color: white;
    cursor: pointer;
}


@media (width < 769px) {
    .float-nav {
        display: flex;
    }

    .question-palette.none {
        display: none;
    }

    .question-palette {
        margin: auto;
    }
}

@media (min-width: 400px) {
    .table {
        width: 50%;
    }
}


@media (width < 1023px) {
    .reading-content {
        height: 100vh;
    }
}

@media (width < 1439px) {
    .reading-content {
        height: 100vh;
    }
}

@media (width > 1440px) {
    .reading-content {
        height: 100vh;
    }
}